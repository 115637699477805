import React from 'react'
import PatternImg from '../../5_assets/svg/pattern_2.svg'

function BackgroundPattern({ height = '45rem' }) {
   return (
      <div
         className={`h-[${height}] fixed -z-100 opacity-10
                   sm:w-[170%] xxs:w-[140%] xs:w-[120%] xs:w-[200%] w-[270%] md:w-[150%] lg:w-[140%] xl:w-[130%]
                   left-1/2 -translate-x-1/2 transform overflow-y-hidden
                   md:-top-[10rem] lg:-top-[0rem] xl:-top-[0rem]`}
      >
         {/* <span
            className={`from-background--dark via-background--dark/50 absolute
                    inset-0
                    bottom-0
                    bg-linear-to-t
                    to-transparent`}
         ></span> */}
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
