import React, { useEffect } from 'react'
import { animated, useSpring } from 'react-spring'

function TransitionPageIn({ children }) {
   const spring = { mass: 1, tension: 1, friction: 1 }

   const fadeIn = useSpring({
      config: { ...spring, duration: 100 },
      from: {
         opacity: 0,
         transform: `translate3d(0rem,0)`,
      },
      to: {
         opacity: 1,
         transform: `translate3d(0rem,0,0)`,
      },
   })

   useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'instant' })
   }, [])

   return <animated.div style={fadeIn}>{children}</animated.div>
}

export default TransitionPageIn
