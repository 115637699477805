import React, { useState, useEffect } from 'react'

const FadeOutOnScroll = ({ scroll = 100, children }) => {
   const [scrollPosition, setScrollPosition] = useState(0)

   useEffect(() => {
      const handleScroll = () => setScrollPosition(window.scrollY)
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => window.removeEventListener('scroll', handleScroll)
   }, [])

   const opacityClass = scrollPosition > scroll ? 'opacity-0' : 'opacity-100'
   const translateClass = scrollPosition > scroll ? '-translate-y-6' : 'translate-y-0'

   return (
      <div className={`transition-all duration-700 ease-in-out delay-0 ${opacityClass} ${translateClass}`}>
         {children}
      </div>
   )
}

export default FadeOutOnScroll
