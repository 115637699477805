import React from 'react'
import { Link } from 'gatsby'

function Button({
   linkTo,
   href,
   label,
   title,
   iconLeft,
   iconRight,
   buttonSize = 'md',
   containerCustomClasses,
   textCustomClasses,
   onClick,
   isRounded = true,
   variant = 'primary', // primary, secondary, default
   hasDot = true,
   hasShadow = false,
}) {
   const baseClasses = `group/button flex items-center space-x-1.5 text-center ${hasShadow ? 'drop-shadow-xl' : ''}`

   const variantClasses = {
      primary:
         'bg-surface-1 hover:bg-green-600 border-1 border-border-color--light hover:drop-shadow-xl transform hover:-translate-y-1 transition ease-in-out duration-500',
      secondary: 'bg-surface-3 hover:bg-surface-5 border-1 border-border-color--light',
      default: 'bg-surface-1 hover:bg-surface-2 border-1 border-border-color--light',
   }

   const paddingClasses = {
      xs: 'py-1.5 pr-4 pl-4',
      sm: 'py-2 pt-2 pr6 pl-5',
      md: 'py-2.5 px-5 3md:pl-6 3md:pr-6',
   }

   const textClasses = `body text-nowrap ${
      {
         xs: 'text-xs!',
         sm: 'text-sm! tracking-tight',
         md: 'text-md! mb-0.5 tracking-tight!',
      }[buttonSize] || ''
   }`

   const roundedClasses = isRounded ? 'rounded-full' : 'rounded-sm'

   const containerClasses = `${baseClasses} ${variantClasses[variant] || variantClasses.default} ${
      paddingClasses[buttonSize] || ''
   } ${roundedClasses} ${containerCustomClasses}`

   const iconSizes = {
      xs: 'w-5 md:w-5',
      sm: 'w-7 md:w-7',
      md: 'w-7 md:w-7',
   }

   const iconClasses = `text-inherit w-${iconSizes[buttonSize] || 6} text-inherit`

   const dotSizes = {
      xs: 'w-1.5 h-1.5',
      sm: 'w-2 h-2',
      md: 'w-2.5 h-2.5',
   }

   const dotMargins = {
      xs: 'mt-0.5 mr-2',
      sm: 'mr-0.5 mr-3',
      md: 'h-2.5 mr-3',
   }

   const renderDot = () =>
      hasDot && (
         <div
            className={`relative inline-flex items-center ${dotSizes[buttonSize] || 'h-2 w-2'} ${
               dotMargins[buttonSize] || ''
            }`}
         >
            <div className={`rounded-full bg-teal-300 ${dotSizes[buttonSize] || ''}`} />
            <div
               className={`absolute top-0 left-0 animate-ping rounded-full bg-teal-300 ${dotSizes[buttonSize] || ''}`}
            />
            <div
               className={`absolute top-0 left-0 animate-pulse rounded-full bg-teal-300 ${dotSizes[buttonSize] || ''}`}
            />
         </div>
      )

   const buttonContent = (
      <>
         {iconLeft && <span className={iconClasses}>{iconLeft}</span>}
         {renderDot()}
         <span className={`${textClasses} ${textCustomClasses}`}>{label}</span>
         {iconRight && <span className={iconClasses}>{iconRight}</span>}
      </>
   )

   const sharedProps = {
      className: containerClasses,
      title: title || label,
      'aria-label': title || label,
      role: 'button',
      onClick,
   }

   const ButtonComponent = linkTo ? Link : href ? 'a' : 'button'

   return (
      <ButtonComponent
         to={linkTo}
         href={href}
         target={href ? '_blank' : undefined}
         rel={href ? 'noopener noreferrer' : undefined}
         {...sharedProps}
      >
         {buttonContent}
      </ButtonComponent>
   )
}

export default Button
