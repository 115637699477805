import React from 'react'
import { Link } from 'gatsby'
import ContainerSite from '../3_layouts/ContainerSite'
import IconCopyright from '../5_assets/svg/icons/copyright.svg'
import IconLinkedin from '../5_assets/svg/icons/linkedin.svg'
import IconInstagram from '../5_assets/svg/icons/instagram.svg'
import IconMail from '../5_assets/svg/icons/mail-fill.svg'

const textClasses = 'body-sm text-body-primary--bright'

const footerLinks = [
   { label: 'Case studies', to: '/#work', aria: 'Link to work section' },
   { label: 'About', to: '/#about', aria: 'Link to about section' },
   { label: 'Recommendations', to: '/#recommendations', aria: 'Link to recommendations section' },
   { label: 'Contact', to: '/#contact', aria: 'Link to contact section' },
]

const socialIcons = [
   { href: 'https://www.linkedin.com/in/paolo-todde/', icon: IconLinkedin, label: 'LinkedIn' },
   { href: 'https://www.instagram.com/paolo__todde/', icon: IconInstagram, label: 'Instagram' },
   { href: 'mailto:iampaolotodde@gmail.com', icon: IconMail, label: 'Email' },
]

const FooterLink = ({ to, label, aria }) => (
   <li aria-label={aria}>
      <Link to={to} title={label}>
         <p className={`${textClasses} text-link font-mono uppercase `}>{label}</p>
      </Link>
   </li>
)

const SocialIcon = ({ href, icon: Icon, label }) => (
   <li className="group">
      <a className="" title={label} aria-label={`${label} icon`} href={href} target="_blank" rel="noopener noreferrer">
         <Icon alt={`${label} icon`} className="w-4" />
      </a>
   </li>
)

const ExternalLink = ({ href, label, children }) => (
   <a
      className={`${textClasses} text-link`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Link to ${label}`}
   >
      {children}
   </a>
)

const Footer = () => {
   const currentYear = new Date().getFullYear()

   return (
      <footer
         className="bg-surface-1 overflow-hidde space-bottom-xl space-top-xl border-border-color--light w-full border-t"
         role="group"
         aria-label="Footer"
      >
         <ContainerSite>
            <div className="flex flex-col md:flex-row justify-between space-y-8 text-center items-center md:text-left">
               <ul className="flex flex-col items-center md:items-start space-y-2">
                  {footerLinks.map((link, index) => (
                     <FooterLink key={index} {...link} />
                  ))}
               </ul>
               <ul className="flex justify-center space-x-8">
                  {socialIcons.map((icon, index) => (
                     <SocialIcon key={index} {...icon} />
                  ))}
               </ul>
               <li className={`${textClasses} flex items-center justify-center space-x-0`}>
                  <IconCopyright className="inline-flex h-full w-5 md:mr-1" />
                  <p className={`${textClasses}`}>{currentYear}</p>
               </li>
               <div className="flex flex-col space-y-3 lg:p-0">
                  <p className={`${textClasses}`}>
                     Designed in{' '}
                     <ExternalLink href="https://www.figma.com/" label="Figma">
                        Figma
                     </ExternalLink>
                  </p>
                  <p className={`${textClasses}`}>
                     Coded with{' '}
                     <ExternalLink href="https://www.gatsbyjs.com/" label="Gatsby">
                        Gatsby.js
                     </ExternalLink>
                  </p>
                  <p className={`${textClasses}`}>
                     Styled with{' '}
                     <ExternalLink href="https://tailwindcss.com/" label="Tailwindcss">
                        Tailwind CSS
                     </ExternalLink>
                  </p>
               </div>
            </div>
         </ContainerSite>
      </footer>
   )
}

export default Footer
