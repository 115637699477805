import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import ContainerSite from '../3_layouts/ContainerSite'
import Navigation from '../2_sections/Navigation'
import Footer from '../2_sections/Footer'
import TransitionPageIn from '../0_transitions/TransitionPageIn.js'
import ScrollToTopButton from '../1_components/ScrollToTopButton'
import BackgroundPattern from '../5_assets/static/BackgroundPattern.js'
import FadeOutOnScroll from '../0_transitions/FadeOutOnScroll.js'

export const DarkThemeContext = createContext()

const useDarkMode = (location) => {
   const [darkMode, setDarkMode] = useState(true)

   useEffect(() => {
      setDarkMode(!['/project/', '/article/'].some((path) => location.pathname.startsWith(path)))
   }, [location.pathname])

   const toggleDarkMode = () => {
      setDarkMode(!darkMode)
   }

   return { darkMode, toggleDarkMode }
}

const ScrollToTopContainer = () => (
   <div className="fixed right-[6%] bottom-[8%] flex space-x-2 p-7 pr-0 pb-0">
      <ScrollToTopButton />
   </div>
)

const Layout = ({ children, location }) => {
   const { darkMode, toggleDarkMode } = useDarkMode(location)
   const isLightBackground = location.pathname.includes('/project/') || location.pathname.includes('/article/')

   const containerClasses = `
      ${darkMode && 'dark'}
      ${isLightBackground ? 'bg-background--bright' : 'bg-background--dark'}
      relative h-full w-full overflow-hidden
   `

   return (
      <DarkThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
         <div className={containerClasses}>
            <FadeOutOnScroll>{!isLightBackground && <BackgroundPattern />}</FadeOutOnScroll>
            <Navigation />
            <TransitionPageIn>
               <main>
                  <ContainerSite>{children}</ContainerSite>
               </main>
            </TransitionPageIn>
            <ScrollToTopContainer />
            <Footer />
         </div>
      </DarkThemeContext.Provider>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
   location: PropTypes.object.isRequired,
}

export default Layout
