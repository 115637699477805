import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { Link } from 'gatsby'
import ContainerSite from '../3_layouts/ContainerSite'
import Button from '../1_components/Button'
import IconMenu from '../5_assets/svg/icons/menu.svg'
import IconClose from '../5_assets/svg/icons/close.svg'
import Logo from '../5_assets/svg/logos/logo.svg'

const navItems = [
   // { to: '/', text: 'Home' },
   { to: '/#work', text: 'Work' },
   { to: '/#about', text: 'About' },
   { to: '/blog', text: 'Articles' },
   //    { to: "/about#side-projects", text: "Creative Projects" },
]

const NavItems = ({ navItems }) => (
   <div className="hidden items-center md:flex">
      <ul className="h-hull mx-auto flex w-full items-center justify-around">
         <li>
            <Link to={'/'} title={'Home'}>
               <Logo className=" h-4 w-4" />
            </Link>
         </li>
         {navItems.map((item, i) => (
            <li key={i}>
               <Link to={item.to} title={item.text}>
                  <p className="body-xs text-link uppercase">{item.text}</p>
               </Link>
            </li>
         ))}
      </ul>
   </div>
)

const MobileNavItems = ({ navItems, onClick }) => (
   <div className="space-top-header bg-surface-1 h-[2800px] w-full pl-6">
      <ul className="space-top-header w-full space-y-2">
         <li className="text-left">
            <Link to={'/'} title={'Home'} stripHash>
               <div className="inline-flex items-start space-x-1.5 ">
                  <span className="mr-4 pt-2 text-[2.3rem] !text-body-primary--bright">☞</span>
                  <p className="header-md uppercase !text-body-primary--bright">Home</p>
               </div>
            </Link>
         </li>
         {navItems.map((item, i) => (
            <li key={i} onClick={onClick} className="text-left">
               <Link to={item.to} title={item.text} stripHash>
                  <div className="inline-flex items-start space-x-1.5 ">
                     <span className="mr-4 pt-2 text-[2.3rem] !text-body-primary--bright">☞</span>
                     <p className="header-md uppercase !text-body-primary--bright">{item.text}</p>
                  </div>
               </Link>
            </li>
         ))}
         <li>
            <Button
               buttonSize="md"
               isExternalLink={true}
               href="mailto:iampaolotodde@gmail.com"
               label="Contact"
               iconLeft={''}
               textCustomClasses={'text-body-primary--bright'}
               containerCustomClasses={`bg-transparent backdrop-blur-md md:1 w-35 mt-10`}
               hasShadow={false}
            />
         </li>
      </ul>
   </div>
)

const Navigation = () => {
   const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
   const [scrollPosition, setScrollPosition] = useState(0)

   useEffect(() => {
      const handleScroll = () => setScrollPosition(window.scrollY)
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => window.removeEventListener('scroll', handleScroll)
   }, [])

   const toggleMobileNav = () => setIsMobileNavOpen((prev) => !prev)
   const closeMobileNav = () => setIsMobileNavOpen(false)

   const menuAnimation = useSpring({
      transform: isMobileNavOpen ? 'translateX(15%)' : 'translateX(110%)',
      opacity: isMobileNavOpen ? 1 : 1,
      config: { mass: 3, tension: 450, friction: 70 },
   })

   const scrollThreshold = 85
   const scrollAnimation = ({
      belowThreshold = '-translate-y-20',
      aboveThreshold = '-translate-y-2 lg:-translate-y-[2rem]',
   } = {}) => {
      return scrollPosition < scrollThreshold
         ? `transform translate duration-300 ease-in-out ${belowThreshold}`
         : `transform translate duration-300 ease-in-out ${aboveThreshold}`
   }

   return (
      <header className={`fixed top-0 right-0 left-0 z-50 w-full`}>
         <ContainerSite>
            <div
               className={`flex justify-between ${
                  scrollPosition > scrollThreshold ? 'mt-[1.5rem] md:mt-[2.5rem]' : 'mt-[6rem] md:mt-[7rem]'
               } ${scrollAnimation()}`}
            >
               <nav
                  className={`border-border-color--light h-full w-full rounded-full md:w-[17rem]  md:border-1 md:pt-2 md:pb-1.5 md:backdrop-blur-md
                  lg:w-[16rem]`}
               >
                  <NavItems navItems={navItems} />
                  <button className="md:hidden" onClick={toggleMobileNav}>
                     {isMobileNavOpen ? (
                        <IconClose className="text-colour h-6 w-6" />
                     ) : (
                        <IconMenu className="text-colour h-6 w-6" />
                     )}
                  </button>

                  <animated.div
                     className="absolute -top-10 right-0 bottom-0 left-0 md:hidden z-10"
                     style={menuAnimation}
                     onClick={closeMobileNav}
                  >
                     <MobileNavItems navItems={navItems} onClick={closeMobileNav} />
                  </animated.div>
               </nav>
               <Button
                  buttonSize="xs"
                  isExternalLink={true}
                  href="mailto:iampaolotodde@gmail.com"
                  label="Contact"
                  iconLeft={''}
                  // textCustomClasses={'text-body-primary--bright'}
                  containerCustomClasses={`bg-transparent backdrop-blur-md md:1`}
                  hasShadow={false}
               />
            </div>
         </ContainerSite>
      </header>
   )
}

export default Navigation
