import React, { useState, useEffect, useCallback } from 'react'
import IconArrow from '../5_assets/svg/icons/arrow-curve-up.svg'

const ScrollToTop = ({ showBelow = 900, label = '' }) => {
   const [show, setShow] = useState(false)

   const handleScroll = useCallback(() => {
      setShow(window.scrollY > showBelow)
   }, [showBelow])

   useEffect(() => {
      if (showBelow) {
         window.addEventListener('scroll', handleScroll, { passive: true })
         return () => window.removeEventListener('scroll', handleScroll)
      }
   }, [showBelow, handleScroll])

   const handleClick = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
   }

   return (
      <>
         <button
            onClick={handleClick}
            aria-label="Scroll back to top of page"
            title="Back to top"
            className={`mx-auto inline-block p-2.5
                      bg-surface-2 hover:bg-surface-3
                       transform rounded-full border-1 border-border-color--light
                       transition duration-500 ease-in-out hover:-translate-y-1 hover:drop-shadow-xl
                      ${show ? 'animate-fadeIn' : 'animate-fadeOut! opacity-0 transition-all duration-100 ease-in-out'}`}>
            <IconArrow className="w-7 rotate-360" alt="icon arrow up" />
            <span className="sr-only">{label}</span>
         </button>
         {label && <p className="body-sm space-top-xxs font-mono">{label}</p>}
      </>
   )
}

export default ScrollToTop
